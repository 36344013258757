/*Color Palette*/
:root {
    --link-a-color: rgb(110, 130, 140);
    --generic-text-color: rgb(255, 255, 255);
    --generic-background-color: rgb(0, 0, 0);
    --page-title-color: rgba(255, 255, 255, 0.1);
    --page-subtitle-color: rgba(255, 255, 255, 0.5);
    --section-title-color: rgb(70, 110, 140);
    --blue-title-shadow-color: rgb(60, 80, 90);
    --white-title-shadow-color: rgb(0, 0, 0);
    --blue-shadow-color: rgb(60, 80, 90);
    --main-title-color: rgb(100, 140, 150);
    --loader-color: rgb(60, 80, 90);
    --header-shade: rgba(0, 0, 0, 0.7);
    --background-shade: rgba(0, 0, 0, 0.5);
    --generic-border-color: rgb(33, 33, 33);
    --container-border-color: rgb(255, 255, 255);
    --main-border-color: rgb(60, 80, 90);
    --mobile-menu-close-button-color: rgb(255, 255, 255);
    --mobile-menu-background-color: rgb(0, 0, 0);
    --sound-control-bar-background-color: rgb(60, 80, 90);
    --skillbar-background: rgb(66, 66, 66);
    --skillbar-hover-effect-color: rgb(0, 100, 200);
    --skillbar-fill-color: rgb(0, 110, 140);
    --more-skill-background-color: rgba(0, 100, 100, 0.5);
    --container-hover-color: rgb(0, 110, 140);
    --important-text-hover-color: rgb(210, 210, 50);
    --project-detail-visit-button-color: rgb(0, 140, 190);
    --project-detail-nav-background-color: rgba(100, 100, 100, 0.2);
}

/* fonts */
@font-face {
    font-family: "Jost";
    src: url("./fonts/Jost-200-Thin.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Jost";
    src: url("./fonts/Jost-300-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* toastify */
.toastify {
    padding: 12px 20px;
    color: #ffffff;
    display: inline-block;
    box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 0 10px 36px -4px rgba(77, 96, 232, 0.3);
    background: -webkit-linear-gradient(315deg, #73a5ff, #5477f5);
    background: linear-gradient(135deg, #73a5ff, #5477f5);
    position: fixed;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    max-width: calc(50% - 20px);
    z-index: 2147483647;
}

.toastify.on {
    opacity: 1;
}

.toast-close {
    opacity: 0.4;
    padding: 0 5px;
}

.toastify-right {
    right: 15px;
}

.toastify-left {
    left: 15px;
}

.toastify-top {
    top: -150px;
}

.toastify-bottom {
    bottom: -150px;
}

.toastify-rounded {
    border-radius: 25px;
}

.toastify-avatar {
    width: 1.5em;
    height: 1.5em;
    margin: -7px 5px;
    border-radius: 2px;
}

.toastify-center {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
    max-width: -moz-fit-content;
}

@media only screen and (max-width: 360px) {
    .toastify-right, .toastify-left {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        max-width: fit-content;
    }
}

/* common */
html, body {
    height: 100%;
    color: var(--generic-text-color);
    background-color: var(--generic-background-color);
    margin: 0;
    padding: 0;
    position: relative;
    font-family: "Jost", serif;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: var(--link-a-color);
}

p {
    opacity: 0.8;
}

#webgl {
    position: fixed;
    z-index: 2;
    opacity: 0;
    transition: 1s opacity linear;
}

#webgl.show {
    opacity: 1;
}

.page {
    width: 100%;
    position: absolute;
    transform: translate3d(0, 20%, 0);
    top: 0;
    left: 0;
    z-index: 0;
    background: none;
    opacity: 0;
    padding-bottom: 10vh;
    transition: 0.8s linear;
}

.page.show {
    z-index: 100;
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.page.hide {
    display: none;
}

.page-title {
    user-select: none;
    white-space: nowrap;
    position: absolute;
    top: 12vh;
    font-size: 12em;
    text-transform: uppercase;
    pointer-events: none;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    color: var(--page-title-color);
    letter-spacing: 2vw;
    font-weight: 300;
}

.page-subtitle {
    user-select: none;
    position: relative;
    white-space: nowrap;
    pointer-events: none;
    top: 23vh;
    margin: 0 auto;
    color: var(--page-subtitle-color);
    text-transform: uppercase;
    letter-spacing: 1vh;
    font-size: 2em;
    text-align: center;
}

.page .feature {
    margin-top: 45vh;
}

.page .feature .feature-image {
    width: 80vw;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
}

.section-title {
    text-align: center;
    pointer-events: none;
    margin-top: 10vh;
    margin-bottom: 5vh;
    text-transform: uppercase;
    text-shadow: 0 3px 10px var(--blue-title-shadow-color);
    letter-spacing: 1vh;
    font-size: 2em;
    color: var(--section-title-color);
}

.data-container {
    border: 3px solid var(--main-border-color);
    border-left: none;
    border-right: none;
    border-radius: 25px;
    background: var(--background-shade);
}

.data-detail-container {
    border: 1px solid var(--container-border-color);
    border-radius: 15px;
    border-right: none;
    border-left: none;
}

.data-detail-button {
    background-color: var(--project-detail-visit-button-color);
    border: 2px solid var(--generic-border-color);
    color: var(--generic-text-color);
    text-align: center;
    display: inline-block;
    border-radius: 15px;
}

/* loader */
.loader {
    opacity: 0;
    width: 0;
    height: 1px;
    background-color: var(--loader-color);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s all, 0.5s opacity;
    z-index: 0;
}

.loader.show {
    opacity: 0.7;
    z-index: 10000;
}

/* STYLE FOR WIDTH LARGE OR HIGHER */
/* header */
.header {
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 12vh;
    top: -5vh;
    left: 0;
    transition: 1s all linear;
    z-index: 1000;
}

.header.show {
    opacity: 1;
    z-index: 1000;
    top: 0;
}

.header.shade {
    background: var(--header-shade);
}

.header .logo {
    cursor: pointer;
    position: absolute;
    top: 1vh;
    left: 2vh;
    z-index: 100;
    width: auto;
    height: 10vh;
}

/* header-desktop-menu */
.header .header-menu {
    z-index: 0;
    position: absolute;
    right: 5vw;
    top: 5vh;
}

.header .header-menu .menu-item {
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.2vw;
    font-size: 1.3em;
    opacity: 0.5;
    padding-right: 7px;
}

/* mobile-menu showButton */
.menu-button {
    display: none;
    width: 50px;
    position: relative;
    float: right;
    top: 30%;
    right: 5%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s linear;
    -moz-transition: .5s linear;
    -o-transition: .5s linear;
    transition: .5s linear;
    cursor: pointer;
}

.menu-button span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: var(--mobile-menu-close-button-color);
    border-radius: 10px;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s linear;
    -moz-transition: .25s linear;
    -o-transition: .25s linear;
    transition: .25s linear;
}

.menu-button span:nth-child(1) {
    top: 0;
}

.menu-button span:nth-child(2) {
    top: 2vh;
}

.menu-button span:nth-child(3) {
    top: 4vh;
}

.menu-button.active span:nth-child(1) {
    top: 2vh;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.menu-button.active span:nth-child(2) {
    opacity: 0;
    left: -12vw;
}

.menu-button.active span:nth-child(3) {
    top: 2vh;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

/* mobile-menu */
.menu {
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
    transition: 1s all;
    z-index: 0;
    background: var(--mobile-menu-background-color);
}

.menu.show {
    opacity: 1;
    z-index: 1000;
    display: block;
}

.menu .menu-items {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding-inline-start: 0;
}

.menu .menu-item {
    cursor: pointer;
    list-style: none;
    opacity: 0.5;
    text-align: center;
    font-size: 2em;
    margin-top: 5vh;
    padding: 10px;
    text-transform: uppercase;
}

.menu .menu-item .menu-item-home {
    margin-top: 0;
}

/* sound controls and animation*/
.sound-controls {
    text-align: center;
    width: 45px;
    position: fixed;
    height: 18px;
    margin-left: auto;
    right: 1vw;
    top: 2vh;
    cursor: pointer;
    z-index: 10000;
}

.sbar {
    height: 1px;
    width: 2px;
    float: right;
    background-color: var(--sound-control-bar-background-color);
    opacity: 1;
    bottom: 5px;
    position: absolute;
    -webkit-animation-duration: 500ms;
    -webkit-animation-play-state: running;
    -webkit-animation-direction: alternate;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-duration: 500ms;
    animation-play-state: running;
    animation-direction: alternate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.sbar.noAnim {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    animation: none !important;
}

#sound-icon {
    position: relative;
    left: 0;
    top: 0.1vh;
    float: left;
}

.sbar#bar-1 {
    right: 0;
    -webkit-animation-name: danceHeight1;
    -webkit-animation-delay: 0ms;
    animation-name: danceHeight1;
    animation-delay: 0ms;
}

.sbar#bar-2 {
    right: 4px;
    -webkit-animation-name: danceHeight2;
    -webkit-animation-delay: 300ms;
    animation-name: danceHeight2;
    animation-delay: 300ms;
}

.sbar#bar-3 {
    right: 8px;
    -webkit-animation-name: danceHeight3;
    -webkit-animation-delay: 600ms;
    animation-name: danceHeight3;
    animation-delay: 600ms;
}

.sbar#bar-4 {
    right: 12px;
    -webkit-animation-name: danceHeight4;
    -webkit-animation-delay: 900ms;
    animation-name: danceHeight4;
    animation-delay: 900ms;
}

@-webkit-keyframes danceHeight1 {
    from {
        height: 1px;
    }
    to {
        height: 10px;
    }
}

@-webkit-keyframes danceHeight2 {
    from {
        height: 1px;
    }
    to {
        height: 12px;
    }
}

@-webkit-keyframes danceHeight3 {
    from {
        height: 1px;
    }
    to {
        height: 14px;
    }
}

@-webkit-keyframes danceHeight4 {
    from {
        height: 1px;
    }
    to {
        height: 8px;
    }
}

@keyframes danceHeight1 {
    from {
        height: 1px;
    }
    to {
        height: 10px;
    }
}

@keyframes danceHeight2 {
    from {
        height: 1px;
    }
    to {
        height: 12px;
    }
}

@keyframes danceHeight3 {
    from {
        height: 1px;
    }
    to {
        height: 14px;
    }
}

@keyframes danceHeight4 {
    from {
        height: 1px;
    }
    to {
        height: 8px;
    }
}

/* home */
.home-bar {
    width: 100%;
    height: 50vh;
    background: var(--generic-background-color);
    position: fixed;
    z-index: 1000;
    transition: 0.3s linear;
}

.top-bar {
    top: 0;
}

.bottom-bar {
    bottom: 0;
}

.home-bar-show {
    height: 10vh;
}

.home-bar-hide {
    height: 0;
    min-height: 0;
}

.home {
    overflow: hidden;
    height: 100%;
    padding-bottom: 0;
    pointer-events: none;
}

.home .titles {
    top: 50%;
    left: 50%;
    z-index: 100;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    pointer-events: none;
    opacity: 1;
}

.home .page-title {
    font-size: 5em;
    letter-spacing: 2.2vw;
    color: var(--main-title-color);
    text-shadow: 0 6px 10px var(--blue-title-shadow-color);
    text-indent: 2.6vw;
    font-weight: 300;
    position: relative;
    top: 0;
    left: auto;
    transform: none;
}

.home .page-akatitle {
    font-size: 1.6em;
    letter-spacing: 1.2vw;
    color: var(--main-title-color);
    text-shadow: 0 6px 10px var(--blue-title-shadow-color);
    text-indent: 2.6vw;
    font-weight: 200;
    position: relative;
    top: -1vh;
    left: auto;
    transform: none;
}

.home .page-subtitle {
    font-size: 1.2em;
    letter-spacing: 1.2vw;
    text-indent: 1.8vw;
    position: relative;
    top: 0;
    left: auto;
    transform: none;
}

.footer-menu {
    z-index: 100;
    padding: 0;
    margin: 0;
    position: fixed;
    bottom: 20vh;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: 0.4s all linear;
}

.footer-menu.show {
    opacity: 1;
}

.footer-menu .menu-items {
    padding: 0;
    margin: 0;
}

.footer-menu .menu-item {
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.2vw;
    text-indent: 0.2vw;
    font-size: 1.3em;
    opacity: 0.5;
    padding-right: 4vw;
    padding-left: 4vw;
    user-select: none;
}

/* info */
.info .bio, .info .skill-intro, .info .ethic {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    font-size: 1.5em;
}

.skillset {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 1vh 4vw 1vh 4vw;
}

.skillset .skill-container {
    grid-column: span 5;
}

.skillset .padder {
    grid-column: span 1;
}

.skill-container .skill-title {
    grid-column: span 11;
    text-align: center;
    pointer-events: none;
    margin-top: 5vh;
    margin-bottom: 4vh;
    text-transform: uppercase;
    letter-spacing: 0.5vw;
    font-size: 1.1em;
    color: var(--main-title-color);
    text-shadow: 0 2px 4px var(--blue-title-shadow-color);
}

.skill-container ul {
    list-style-type: none;
    display: inline;
}

.skillbar-container {
    border: 1px solid var(--generic-border-color);
    background-color: var(--skillbar-background);
    border-radius: 50px;
    margin-bottom: 3vh;
    transition: all 0.05s;
    cursor: pointer;
    animation: all 0.3 linear 0s;
}

.skillbar-container:hover {
    transform: scale(1.03);
    box-shadow: 0px 0px 12px 6px var(--skillbar-hover-effect-color);
}

.skillbar-container i {
    float: left;
    font-size: 1em;
    left: 1vw;
    position: relative;
    top: 0.5vh;
}

.skillbar-container .skill-name {
    letter-spacing: 0.2vw;
    font-size: 1.5em;
    text-shadow: 2px 2px 5px var(--white-title-shadow-color);
    text-indent: 2vw;
    position: fixed;
}

.skillbar-container .skillbar {
    height: 35px;
    border-radius: 50px 0px 0px 50px;
    background-color: var(--skillbar-fill-color);
}

.more-skill-container {
    display: none;
    width: 90%;
    border-radius: 0 0 25px 25px;
    border: 1px solid var(--generic-border-color);
    border-top: none;
    padding: 10px;
    background-color: var(--more-skill-background-color);
    text-align: center;
    margin: -28px auto 2vh;
    font-size: 1.2em;
}

/* projects */
.github-card {
    width: 40vw;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    position: relative;
    font-size: 1.5em;
    padding: 20px;
}

.github-card-avatar {
    border: 2px solid var(--generic-border-color);
    display: inline-block;
    overflow: hidden;
    border-radius: 100%;
    box-shadow: 0 1px 1px var(--blue-shadow-color);
    animation: all .5s linear;
    width: 120px;
    height: auto;
}

.github-card-avatar:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
}

.github-card-title {
    font-size: 1.5em;
    letter-spacing: 0.15vw;
    color: var(--main-title-color);
}

.github-card-content {
    margin-top: 2vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.github-card-element {
    text-transform: uppercase;
    grid-column: span 1;
    padding: 20px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.1s linear 0s;
}

.github-card-element a {
    text-decoration: none;
}

.github-card-element:hover {
    background-color: var(--container-hover-color);
}

.github-card-element:hover a {
    color: var(--important-text-hover-color);
}

.projects-content, .contributions-content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    width: 95%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.list-item {
    grid-column: span 4;
    margin-bottom: 5vh;
    width: 95%;
    height: auto;
    padding-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
}

.list-item img {
    margin-top: 30px;
    width: 100%;
    height: 350px;
    transition: 0.5s opacity;
    opacity: 0.6;
    object-fit: scale-down;
}

.list-item .info .title {
    color: var(--main-title-color);
    text-align: center;
    font-size: 1.5em;
}

.list-item .info .text {
    padding: 0 20px 0 20px;
    font-size: 1em;
}

.list-item .links .detail {
    cursor: pointer;
    font-size: 1.5em;
    color: var(--main-title-color);
    text-align: center;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    transition: background 0.3s linear 0s;
}

.list-item:hover .links .detail {
    background-color: var(--container-hover-color);
    color: var(--important-text-hover-color);
}

.list-item:hover img {
    opacity: 1;
}

/* project detail */
.project-detail .page-title {
    font-size: 4em;
    letter-spacing: 0.8vw;
    top: 30vh;
    color: var(--section-title-color);
}

.project-detail .page-subtitle {
    top: 25vh;
}

.project-detail .feature {
    margin-top: 45vh;
}

.project-detail .feature img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60vw;
    height: auto;
    object-fit: contain;
}

.project-detail .description {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
    font-size: 1.5em;
    text-align: center;
}

.details-container {
    margin-top: 5vh;
    position: relative;
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    padding: 1vh 4vw 1vh 4vw;
}

.details-container .detail {
    margin: 2vh 0 2vh 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
    transition: background 0.3s linear 0s;
    font-size: 1.2em
;
}

.details-container .detail:hover {
    background-color: var(--container-hover-color);
}

.details-container .detail-title {
    text-transform: uppercase;
    grid-column: span 1;
    color: var(--main-title-color);
    transition: background 0.3s linear 0s;
}

.details-container .detail:hover .detail-title {
    color: var(--important-text-hover-color);
}

.details-container .detail-data {
    float: right;
    grid-column: span 2;
    text-align: center;
}

.details-container .detail:hover .detail-data a {
    color: var(--important-text-hover-color);
}

.details-container .detail-button {
    padding: 15px 32px;
}

/* bottom nav */
.bottom-nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: var(--project-detail-nav-background-color);
    height: 6vh;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 1.3em;
}

.bottom-nav .ctlbutton {
    text-transform: uppercase;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    grid-column: span 1;
    opacity: 0.6;
    cursor: pointer;
    transition: 0.3s all linear;
}

.bottom-nav .ctlbutton:hover {
    opacity: 1;
}

/* platforms*/
.platforms .platforms-intro {
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
    height: auto;
    font-size: 1.5em;
}

.platforms .platforms-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 95%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.platforms-container .platform {
    grid-column: span 1;
    margin-bottom: 3vh;
    width: 95%;
    height: 400px;
    margin-right: auto;
    margin-left: auto;
}

.platforms-container .platform .platform-icon {
    font-size: 2em;
    text-align: center;
    margin: 2vh 0 2vh 0;
}

.platforms-container .platform .platform-title {
    color: var(--main-title-color);
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.platforms-container .platform .platform-link {
    cursor: pointer;
    font-size:1.5em;
    color: var(--main-title-color);
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    transition: background 0.3s linear 0s;
}

.platforms-container .platform:hover .platform-link {
    background-color: var(--container-hover-color);
}

.platforms-container .platform:hover .platform-link a {
    color: var(--important-text-hover-color);
}

/* thanks */
.thanks-page-container {
    margin-top: 5vh;
    position: relative;
    width: 75%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 1vh 4vw 1vh 4vw;
}

.thanks-page-container .detail {
    margin: 2vh 0 2vh 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 10px;
    transition: background 0.3s linear 0s;
    font-size:1.3em;
}

.thanks-page-container .detail:hover {
    background-color: var(--container-hover-color);
}

.thanks-page-container .detail-title {
    text-transform: uppercase;
    grid-column: span 1;
    color: var(--main-title-color);
    transition: background 0.3s linear 0s;
}

.thanks-page-container .detail:hover .detail-title {
    color: var(--important-text-hover-color);
}

.thanks-page-container .detail-data {
    float: right;
    grid-column: span 2;
    text-align: center;
}

.thanks-page-container .detail-link {
    width: 70%;
    height: 30px;
    margin: auto 0 auto 2vw;
    float: right;
    grid-column: span 1;
}

.thanks-page-container .detail:hover .detail-link {
    color: var(--important-text-hover-color);
}

/* scrollbar settings -WebKit only */
::-webkit-scrollbar {
    width: 0;
}

@media only screen and (max-width: 1366px) {

    .header .header-menu .menu-item {
        font-size: 1.3em;
    }

    /* info */
    .info .bio, .info .skill-intro, .info .ethic {
        font-size:1.5em;
    }

    /* projects */
    .projects-content, .contributions-content {
        grid-template-columns: repeat(8, 1fr);
    }

    .list-item {
        width: 90%;
    }

    .list-item .info .title {
        font-size: 2em;
    }

    .list-item .info .text {
        font-size: 1.5em;
    }

    .list-item .links .detail {
        font-size: 1.5em;
    }

    /* project detail */
    .project-detail .description {
        font-size: 1.5em;
    }

    .details-container .detail {
        font-size: 1.3em;
    }

    /* bottom nav */
    .bottom-nav {
        font-size: 1.3em;
    }

    /* platforms*/
    .platforms .platforms-intro {
        font-size: 1.5em;
    }

    /* thanks */
    .thanks-page-container .detail {
        font-size: 1.3em;
    }
}

@media only screen and (max-width: 1024px) {
    /* common */
    .home-bar {
        display: none;
    }

    .header-menu {
        display: none;
    }

    .menu-button {
        display: block;
        opacity: 0.5;
    }

    .footer-menu {
        bottom: 5vh;
    }

    .footer-menu .menu-item {
        font-size: 1.5em;
        padding-left: 40px;
        padding-right: 40px;
    }

    .logo {
        display: none;
    }

    .page-title {
        font-size: 5em;
    }

    .page-subtitle {
        top: 17vh;
        font-size: 0.8em;
    }

    .page .feature {
        margin-top: 25vh;
    }

    .section-title {
        margin-top: 5vh;
        margin-bottom: 3vh;
        font-size: 1.5em;
    }

    /* sound controls */
    .sound-controls {
        left: 3vh;
        top: 3vh;
        right: unset;
    }

    /* home */
    .home .page-title {
        font-size: 2em;
    }

    .home .page-akatitle {
        font-size: 1.3em;
    }

    .home .page-subtitle {
        font-size: 0.7em;
        letter-spacing: 0.2vw;
    }

    /* info*/
    .info .bio, .info .skill-intro, .info .ethic {
        font-size: 1.2em;
    }

    .skillset {
        padding: 0 4vw 4vh 4vw;
    }

    .skillset .skill-container {
        grid-column: span 11;
    }

    .skillset .padder {
        display: none;
    }

    .skill-container .skill-title {
        margin-bottom: 0;
        letter-spacing: 0.5vw;
        font-size: 1.3em;
    }

    .skillbar-container i {
        font-size: 1em;
        left: 4vw;
        top: 0.7vh;
    }

    .skillbar-container .skill-name {
        letter-spacing: 0.5vw;
        font-size: 1.2em;
        text-indent: 6vw;
    }

    .skillbar-container .skillbar {
        height: 30px;
    }

    .more-skill-container {
        width: 80%;
        font-size: 1.2em;
    }

    /* projects */
    .projects .feature {
        margin-top: 30vh;
    }

    .github-card {
        width: 80%;
        height: auto;
        font-size: 1.5em;
    }

    .github-card-title {
        font-size: 1.5em;
    }

    .github-card-element {
        margin-top: 20px;
        grid-column: span 3;
    }

    .list-item {
        grid-column: span 12;
        margin-bottom: 20px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: auto;
    }

    .list-item img {
        height: 250px;
    }

    .list-item .info .title {
        font-size: 1.5em;
    }

    .list-item .info .text {
        padding: 0 20px 0 20px;
        font-size: 1.3em;
    }

    .list-item .links .detail {
        padding: 10px;
        font-size: 1.3em;
    }

    /* projectDetail */
    .project-detail .page-title {
        font-size: 3em;
        letter-spacing: 0.8vw;
        top: 18vh;
    }

    .project-detail .page-subtitle {
        top: 15vh;
        font-size: 1.5em;
    }

    .project-detail .feature {
        margin-top: 25vh;
    }

    .project-detail .feature img {
        width: 80%;
        height: auto;
    }

    .details-container {
        margin-bottom: 10vh;
        width: 80%;
    }

    .project-detail .description {
        font-size: 1.3em;
    }

    .details-container .detail {
        margin: 20px 0 20px 0;
        padding: 10px;
        font-size: 1.3em;
    }

    .details-container .detail-title {
        text-align: center;
        grid-column: span 3;
    }

    .details-container .detail-data {
        grid-column: span 3;
    }

    .details-container .detail-button {
        background-color: var(--project-detail-visit-button-color);
        border: 2px solid var(--generic-border-color);
        color: var(--generic-text-color);
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border-radius: 16px;
    }

    /* bottom nav */
    .bottom-nav {
        margin-top: 20px;
        height: auto;
        font-size: 1.5em;
    }

    .bottom-nav .ctlbutton {
        margin-top: 5px;
        margin-bottom: 5px;
        grid-column: span 3;
    }

    /* platforms*/
    .platforms .page-title {
        font-size: 4em;
    }

    .platforms .page-subtitle {
        top: 16vh;
    }

    .platforms .platforms-intro {
        font-size: 1.3em;
    }

    .platforms-container .platform {
        width: 90%;
        grid-column: span 3;
        margin-bottom: 20px;
        height: auto;
        padding: 20px;
    }

    .platforms-container .platform .platform-icon {
        font-size: 1.5em;
        margin: 20px 0 20px 0;
    }

    .platforms-container .platform .platform-title {
        font-size: 2em;
        margin-bottom: 10px;
    }

    .platforms-container .platform .platform-link {
        font-size: 1.3em;
        width: 80%;
        padding: 10px;
    }

    /* thanks */
    .thanks-page-container .detail {
        margin: 20px 0 20px 0;
        padding: 10px;
        font-size: 1.3em;
    }

    .thanks-page-container .detail-title {
        margin-top: 20px;
        text-align: center;
        grid-column: span 4;
    }

    .thanks-page-container .detail-data {
        margin-top: 20px;
        grid-column: span 4;
    }

    .thanks-page-container .detail-link {
        height: 30px;
        margin: 20px auto 20px auto;
        width: 50%;
        grid-column: span 4;
    }
}
